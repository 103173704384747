import React from 'react';
/*import { ReactComponent as NoPayment } from '../images/no-payment-upfront.svg';
import { ReactComponent as StopPayments } from '../images/stop-payments.svg';
import { ReactComponent as RecoverDownPayment } from '../images/recover-down-payment.svg';
import { ReactComponent as KeepCreditScore } from '../images/keep-clean-credit-score.svg';*/
import StopPayments from './images/StopPayments';
import NoPayment from './images/NoPayment';
import RecoverDownPayment from './images/RecoverDownPayment';
import KeepCreditScore from './images/KeepCreditScore';

import ContactButton from './ContactButton';
import useI18n from '../utils/useI18n';
import '../styles/components/_components.benefits.scss';

const Benefits = ({ contact = true }) => {
  const { t } = useI18n();
  return (
    <section className="c-section o-wrapper c-benefits">
      <h2 className="o-largeTitle">
        {t('benefits.title')}
      </h2>
      <div className="o-layout u-margin-top-large u-margin-bottom">
        <div className="o-layout__item u-3/12@desktop u-6/12@tablet c-benefits__item">
          <div className="c-benefits__img">
            <NoPayment />
          </div>
          <strong className="c-benefits__title">{t('benefits.noPaymentUpfront')}</strong>
          <p dangerouslySetInnerHTML={{ __html: t('benefits.noPaymentUpfront.content') }} />
        </div>
        <div className="o-layout__item u-3/12@desktop u-6/12@tablet c-benefits__item">
          <div className="c-benefits__img">
            <RecoverDownPayment />
          </div>
          <strong className="c-benefits__title">{t('benefits.recoverPayment')}</strong>
          <p dangerouslySetInnerHTML={{ __html: t('benefits.recoverPayment.content') }} />
        </div>
        <div className="o-layout__item u-3/12@desktop u-6/12@tablet c-benefits__item">
          <div className="c-benefits__img">
            <KeepCreditScore />
          </div>
          <strong className="c-benefits__title">{t('benefits.creditScore')}</strong>
          <p
            dangerouslySetInnerHTML={{
              __html: t('benefits.creditScore.content', { url: t('url.contact') }),
            }}
          />
        </div>
        <div className="o-layout__item u-3/12@desktop u-6/12@tablet c-benefits__item">
          <div className="c-benefits__img">
            <StopPayments />
          </div>
          <strong className="c-benefits__title">{t('benefits.stopPayments')}</strong>
          <p dangerouslySetInnerHTML={{ __html: t('benefits.stopPayments.content') }} />
        </div>
      </div>
      {contact && <ContactButton />}
    </section>
  );
};

export default Benefits;
