import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const RecoverDownPayment = () => {
  const data = useStaticQuery(graphql`
      query {
          placeholderImage: file(relativePath: { eq: "Benefits/recover-payments.webp" }) {
              childImageSharp {
                  fixed(width: 48) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `);

  return <Img fixed={data.placeholderImage.childImageSharp.fixed} fadeIn={false} />;
};

export default RecoverDownPayment;
