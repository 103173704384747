import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const StopPayments = () => {
  const data = useStaticQuery(graphql`
      query {
          placeholderImage: file(relativePath: { eq: "royal-elite-timeshare-scam-victim.webp" }) {
              childImageSharp {
                  fixed(width: 97) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `);

  return <Img fixed={data.placeholderImage.childImageSharp.fixed} fadeIn={false} />;
};

export default StopPayments;
