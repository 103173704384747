import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';

import Layout from '../components/Layout';
import SEO from '../components/seo/SEO';
import FeaturedTestimonial from '../components/FeaturedTestimonial';
import Benefits from '../components/Benefits';
// import Address from '../components/Address';
import useI18n from '../utils/useI18n';

const Contact = ({ pageContext: { switchLang }, location }) => {
  const { t } = useI18n();
  return (
    <Layout switchLang={switchLang}>
      <SEO
        title={t('contact.meta.title')}
        description={t('contact.meta.description')}
        keywords={t('contact.meta.keywords')}
        pathname={location.pathname}
        altTitle={t('menu.contact')}
      />
      <div className="o-wrapper u-align-center">
        <div className="u-8/12@tablet u-push-2/12@tablet u-margin-top-huge u-margin-bottom-huge">
          <CheckCircleFilled style={{ fontSize: 80, color: '#65D36E', marginBottom: '.5em' }} />
          <span className="u-h1 u-margin-bottom-small">{t('contact.thanks.title')}</span>
          <div dangerouslySetInnerHTML={{ __html: t('contact.thanks.text') }} />
        </div>
      </div>
      {/* <Address /> */}
      <FeaturedTestimonial />
      <Benefits contact={false} />
    </Layout>
  );
};

export default Contact;
