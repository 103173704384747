import React from 'react';
import { RightCircleOutlined } from '@ant-design/icons';

import TestimonialPic from './images/TestimonialPic';
import useI18n from '../utils/useI18n';
import Link from './Link';

import '../styles/components/_components.featuredTestimonials.scss';

const FeaturedTestimonial = () => {
  const { t } = useI18n();

  return (
    <section className="c-section c-section--white">
      <div className="c-section__content o-wrapper">
        <h2 className="o-largeTitle">{t('featuredTestimonial.title')}</h2>
        <div className="u-10/12@desktop u-push-1/12@desktop c-featuredTestimonial">
          <div className="c-featuredTestimonial__img">
            <TestimonialPic />
          </div>
          <div className="c-featuredTestimonial__body">
            <blockquote>
              “{t('featuredTestimonial.content')}”
            </blockquote>
            <span className="c-featuredTestimonial__autor">
              July Syms | <strong>{t('featuredTestimonial.resort')}</strong>
            </span>
            <a href={`${t('url.testimonials')}/`}>
              {t('featuredTestimonial.viewMore')} <RightCircleOutlined />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedTestimonial;
